<template>
  <div class="spoiler-section">
    <SideFlyout
      v-model:isVisible="isMessagesVisible"
      side="right"
      :title="screenSize === 'mobile' ? 'Message' : `Message ${creatorDetails?.firstName}`"
      :subTitle="isMessageThreadImpersonationOn ? 'Impersonating the customer' : ''"
    >
      <CollabMessagesThread
        class="messages-thread-flyout"
        :messageThreadId="collabInput.collaboration.messageThreadId"
        :customerId="collabInput.customerId"
        isCreatorDetailsVisible
        :creatorDetails="creatorDetails"
        :collabInputId="collabInput.id"
        messageSenderType="CustomerId"
        @isImpersonateChange="value => (isMessageThreadImpersonationOn = value)"
      />
    </SideFlyout>

    <CreateCollabModal
      v-if="communityId"
      :communityId="communityId"
      :collabInputForEditFlow="collabInput"
      :collabRemainingCounts="collabRemainingCounts"
      :visible="showEditCollabWizard"
      :openingEditFlow="openingEditFlow"
      @close="showEditCollabWizard = false"
    />

    <!--Delete Modal-->
    <SrpModal v-model:isVisible="showDeleteModal" size="small">
      <template #header><h2 class="global-h2">Are you sure you want to delete this Creator Visit?</h2></template>
      <template #footer>
        <SrpButton @click="cancelDelete" color="gray" fill="outlined" style="margin-right: 10px">Cancel</SrpButton>
        <SrpButton @click="deleteCollab" :isDisabled="saveStatus != null" color="orange">Yep, delete it</SrpButton>
      </template>
    </SrpModal>

    <!--Send to Creators Modal-->
    <SrpModal v-model:isVisible="showSendToCreatorsModal" size="small">
      <template #header><h2 class="global-h2">Ready to send this out to Creators?</h2></template>
      <template #content>
        Are you ready to to send this to creators?<br />
        Make sure it has the details you want to share with the creators.
      </template>
      <template #footer>
        <SrpButton @click="cancelSendToCreators" color="gray" fill="outlined" style="margin-right: 10px">Not yet</SrpButton>
        <SrpButton @click="sendToCreators" :isDisabled="saveStatus != null">Yes, Send to Creators!</SrpButton>
      </template>
    </SrpModal>

    <SrpDetailsSummary class="spoiler-section__details-summary" v-model:is-opened="isCollabDetailsSpoilerOpened" :transition-duration="150">
      <template #heading="{ isOpened }">
        <div
          :class="{
            'global-h3': true,
            'spoiler-section__summary': true,
            'spoiler-section__summary--with-bottom-padding': !isOpened,
          }"
        >
          <span><span>Creator Visit Details</span></span>
        </div>
      </template>
      <template #details>
        <div class="spoiler-section__content" ref="spoilerSection1">
          <NoteWithIcon v-if="!creatorDetails && invitedCreatorsStore.getCreatorsList(communityId, collabInput.id)?.length" class="spoiler-section__note" color="green">
            <template #icon><IconEmbedded name="info-simple_4" :size="26" /></template>
            <template #text>
              <div class="choose-creator-note-content" style="width: 100%">
                <div class="choose-creator-note-content__text">
                  <div class="global-h4">Creators Applied!</div>
                </div>

                <!-- Creator avatars row -->
                <ul class="creator-avatars-row choose-creator-note-content__creator-avatars-row">
                  <li class="creator-avatars-row__avatar-container">
                    <img class="creator-avatars-row__avatar" :src="invitedCreatorsStore.getCreatorsList(communityId, collabInput.id)?.[0].profileImageUri" alt="Avatar" />
                  </li>
                  <li v-if="invitedCreatorsStore.getCreatorsList(communityId, collabInput.id)?.[1]" class="creator-avatars-row__avatar-container">
                    <img class="creator-avatars-row__avatar" :src="invitedCreatorsStore.getCreatorsList(communityId, collabInput.id)?.[1].profileImageUri" alt="Avatar" />
                  </li>
                  <li v-if="invitedCreatorsStore.getCreatorsList(communityId, collabInput.id)?.[2]" class="creator-avatars-row__avatar-container">
                    <img class="creator-avatars-row__avatar" :src="invitedCreatorsStore.getCreatorsList(communityId, collabInput.id)?.[2].profileImageUri" alt="Avatar" />
                  </li>
                  <li
                    v-if="invitedCreatorsStore.getCreatorsList(communityId, collabInput.id)?.length > 3"
                    class="creator-avatars-row__avatar-container creator-avatars-row__avatar-container--with-border"
                  >
                    <div class="creator-avatars-row__counter">+{{ invitedCreatorsStore.getCreatorsList(communityId, collabInput.id).length - 3 }}</div>
                  </li>
                </ul>
                <!-- / Creator avatars row -->

                <SrpButton class="choose-creator-note-content__choose-creator-btn" size="small" @click="$emit('scrollToCreators')">Choose a Creator</SrpButton>
              </div>
            </template>
          </NoteWithIcon>

          <!-- Checklist/guidance/creator -->
          <div class="checklist-guidance-creator spoiler-section__checklist-guidance-creator">
            <div class="checklist-guidance-creator__column checklist-guidance-creator__column--left">
              <div class="global-h4 checklist-guidance-creator__title">Checklist</div>
              <VisitCheckList
                class="checklist-guidance-creator__visit-checklist"
                :checklistRows="checklistRows"
                :collabRemainingCounts="collabRemainingCounts"
                v-model:lodgingDetailsCollapsed="lodgingDetailsCollapsed"
                v-model:isVisitPlanCollapsed="isVisitPlanCollapsed"
                :collabInput="collabInput"
                :creatorInput="creatorInput"
                v-model:compDetailsCollapsed="compDetailsCollapsed"
                :adventuresAreCreated="adventuresAreCreated"
                :calculateChecklist="calculateChecklist"
                @openChat="showMessageThread"
                :communityId="communityId"
                :creatorName="creatorDetails?.firstName"
                :isSavingCollabInput="isSaving"
                @requestCollabInputSave="save"
              />
            </div>

            <div class="checklist-guidance-creator__column checklist-guidance-creator__column--center">
              <div class="global-h4 checklist-guidance-creator__title">Guidance</div>
              <CollabInputsSummary
                class="checklist-guidance-creator__visit-guidance"
                :isCreatorFlow="false"
                :collabInput="collabInput"
                :creatorInput="creatorInput"
                :creatorName="creatorDetails?.firstName"
              />

              <SrpButton v-if="canEditInputs" @click="openEditWizard" size="tiny" style="margin-top: 17px">Edit</SrpButton>
              <!--Super users can edit at any point-->
              <LinkWithIcon v-else-if="isSuperOrSalesUser" @click="openEditWizard" style="margin-top: 17px" isDottedUnderline><span>super edit</span></LinkWithIcon>
            </div>

            <div class="checklist-guidance-creator__column checklist-guidance-creator__column--right">
              <div class="global-h4 checklist-guidance-creator__title">Creator</div>

              <div class="checklist-guidance-creator__selected-creator-section">
                <div class="field">
                  <div v-if="creatorInput && creatorDetails">
                    <AvatarWithFallback :src="creatorDetails.profileImageUri" style="width: 70%; max-width: 210px; margin-bottom: 12px" />

                    <div class="content">
                      <div style="margin-bottom: 10px">
                        <router-link target="_blank" :to="{ name: 'CreatorProfilePublic', params: { creatorId: creatorDetails.uriKey } }" class="global-h2 creator-name-link" style="margin-right: 5px">
                          {{ creatorDetails.firstName }}
                        </router-link>

                        <LinkWithIcon
                          v-if="isSuperOrSalesUser"
                          :to="{ name: 'CollabOpportunity', params: { creatorId: creatorDetails.sherpaId, communityId: communityId, collabInputId: collabInput.id } }"
                          tag="RouterLink"
                        >
                          <template #icon><IconEmbedded name="trending-up_2-5" :size="21" /></template>
                        </LinkWithIcon>
                      </div>
                      <!--<div class="meta">
                        <i class="envelope outline icon"></i> {{creatorDetails.email}}
                      </div>-->
                      <div v-if="creatorInput.notesForCommunity" class="description" style="margin-bottom: 7px; word-break: break-word">
                        <i class="quote left icon" style="margin-right: 8px"></i>
                        <div v-if="creatorInput?.notesForCommunity.length < 250" v-html="linkifyStr(creatorInput.notesForCommunity, globalRoot.linkifyOptions)"></div>

                        <template v-else>
                          <span
                            v-html="linkifyStr(isDescriptionFullyVisible ? creatorInput.notesForCommunity : creatorInput.notesForCommunity.slice(0, 250) + '&hellip;', globalRoot.linkifyOptions)"
                          ></span
                          >&nbsp;

                          <div v-if="isDescriptionFullyVisible" class="show-more-link" @click="isDescriptionFullyVisible = false">
                            <span class="show-more-link__text">show less</span>
                          </div>
                          <div v-else class="show-more-link" @click="isDescriptionFullyVisible = true">
                            <span class="show-more-link__text">show more</span>
                          </div>
                        </template>
                      </div>
                    </div>
                    <div v-if="creatorInput.travelingWith" class="extra content">
                      <i class="user icon"></i>
                      Traveling with: {{ creatorInput.travelingWith }}
                    </div>

                    <SrpButton @click="showMessageThread" size="tiny" style="margin-top: 14px">
                      <template #icon><IconEmbedded name="chat_2-5" :size="18" /></template>
                      Message
                      {{ (creatorDetails?.firstName).slice(0, 20) + (creatorDetails?.firstName.length > 20 ? "&hellip;" : "") }}
                      <span
                        v-if="newMessagesCount > 0"
                        style="
                          min-width: 20px;
                          height: 20px;
                          padding: 0 5px;
                          border-radius: 100px;
                          display: flex;
                          justify-content: center;
                          align-items: center;
                          position: absolute;
                          inset: -6px -6px auto auto;
                          color: #fff;
                          font-weight: bold;
                          font-size: 12px;
                          line-height: 12px;
                          background: #ec563b;
                          pointer-events: none;
                        "
                      >
                        {{ newMessagesCount > 9 ? "9+" : newMessagesCount }}
                      </span>
                    </SrpButton>
                  </div>
                  <template v-if="!creatorInput && !creatorDetails">
                    <AvatarWithFallback style="width: 70%; max-width: 210px; margin-bottom: 12px" />
                    <div>Creator not chosen yet</div>
                    <SrpButton :isDisabled="true" size="tiny" fill="outlined" color="gray" style="margin-top: 14px">
                      <template #icon><IconEmbedded name="chat_2-5" :size="18" /></template> Message Creator
                    </SrpButton>
                  </template>
                </div>
              </div>
            </div>
          </div>
          <!-- / Checklist/guidance/creator -->

          <ContactShrpaAndEmails style="margin-bottom: 24px" :collabInput="collabInput" :pageId="communityId" @update:collabInput="newCollabInput => $emit('update:collabInput', newCollabInput)" />

          <div class="spoiler-section__horizontal-divider"></div>

          <!--Note: The below code was previously in the CommunityInput.vue before we added the Collab Wizard to replace that-->
          <NoteWithIcon v-if="isCompleted" color="green" style="margin-bottom: 20px">
            <template #icon><IconEmbedded name="info-simple_4" /></template>
            <template #text>
              <h5 class="global-h5">Creator Visit Completed!</h5>
            </template>
          </NoteWithIcon>

          <!-- "Send to Creators" flow -->
          <template v-else>
            <h5 class="global-h5" v-if="isSuperOrSalesUser && !collabInput.postedToCreators && collabInput.readyToPost" style="position: relative; z-index: 6">
              <b style="color: firebrick">Ready to Post!</b><br />
              Once approved, click Send to Creators below to Post.<br />
              <SrpButton style="margin-top: 18px" @click="askToSendToCreators">Approve Send to Creators!</SrpButton>
            </h5>

            <div v-if="!collabInput.postedToCreators && !collabInput.readyToPost && communityCreatorMatchSummary" style="padding: 0 0 25px 0; margin: 25px 0 5px">
              <div
                :style="{
                  padding: ['mobile', 'tablet'].includes(screenSize) ? '17px 22px 20px' : '20px 30px',
                  display: 'flex',
                  alignItems: ['mobile', 'tablet', 'tablet-large'].includes(screenSize) ? 'flex-start' : 'center',
                  flexDirection: ['mobile', 'tablet', 'tablet-large'].includes(screenSize) ? 'column' : 'row',
                }"
                style="border-radius: 6px; background: #f0f0f0"
              >
                <h4
                  class="global-h3"
                  :style="{
                    margin: ['mobile', 'tablet', 'tablet-large'].includes(screenSize) ? '0 55px 15px 0' : '25px 55px 25px 0',
                    fontWeight: 400,
                  }"
                >
                  There are amazing creators<br />ready to apply for your visit!
                </h4>

                <div style="display: flex; flex-direction: row; align-items: center">
                  <img v-for="creatorImage in communityCreatorMatchSummary.exampleCreatorImages" :key="creatorImage" class="ui circular image creator-image" :src="creatorImage" alt="Creator" />
                  <i class="large grey ellipsis horizontal icon"></i>
                </div>
              </div>
            </div>

            <!--{{(communityCreatorMatchSummary ? communityCreatorMatchSummary.paidCreatorsInRange : 'so many')}}-->

            <!--Errors-->
            <div v-if="errors.length > 0" style="color: red; margin-bottom: 15px">
              <h5 style="margin: 10px 0 0 0">Required Fields</h5>
              <div v-for="(error, i) in errors" :key="'error' + i">-{{ error }}</div>
            </div>
          </template>

          <!-- Manage buttons panel -->
          <div
            v-if="isManagingButtonsPanelVisible"
            :class="{
              'manage-buttons-panel': true,
              'manage-buttons-panel--stuck': isButtonsPanelStuck,
              'spoiler-section__manage-buttons-panel': true,
              'spoiler-section__manage-buttons-panel--stuck': isButtonsPanelStuck,
            }"
          >
            <div
              :class="{
                'manage-buttons-panel__left-side': true,
                'manage-buttons-panel__left-side--hidden': isButtonsPanelStuck,
              }"
            >
              <SrpButton v-if="allowDelete" @click="askToDelete" color="gray" size="tiny" fill="outlined" style="margin-right: 12px; opacity: 0.5">Delete</SrpButton>
              <h3 v-if="saveStatus" style="margin: 0">{{ saveStatus }}</h3>
            </div>

            <div class="manage-buttons-panel__right-side">
              <div v-if="errors.length > 0" style="color: red; margin-right: 15px; font-weight: 600">Complete Required Fields</div>

              <!--<SrpButton v-if="creatorDetails" :size="screenSize === 'mobile' ? 'small' : 'normal'" class="manage-buttons-panel__button" is-bigger-side-paddings @click="save" :is-disabled="isSaving">-->
              <!--  {{ isSaving ? "Saving..." : "Save" }}-->
              <!--</SrpButton>-->

              <template v-if="isSuperOrSalesUser && !collabInput.postedToCreators && collabInput.readyToPost"></template>
              <SrpButton v-else-if="isSendToCreatorsButtonVisible" @click="askToSendToCreators">Send to Creators!</SrpButton>
            </div>
          </div>
          <!-- / Manage buttons panel -->
        </div>
        <div ref="intersectionDetectionTarget"></div>
      </template>
    </SrpDetailsSummary>
  </div>
</template>

<script lang="ts">
import axios from "axios";
import CollabInputValidator from "@logic/CollabInputValidator";
import CollabStateHelper from "@logic/CollabStateHelper";
import CollabStatuses from "@logic/CollabStatuses";
import CollabConsts, { CustomerCollabRemainingCounts } from "@contracts/collab";
import DateUtils from "@logic/DateUtils";
import { defineComponent, inject } from "vue";
import moment from "moment";
import { RouteHelper } from "@helpers/RouteHelper";

// Types
import { CollabAssignedCreator, CollabCreatorInput, CollabInput } from "@contracts/collab";
import { CollabChecklistRow } from "@contracts/collabChecklist";
import { ItinerarySummary } from "@contracts/itinerary";
import { ScreenSize } from "@contracts/screenSize";

// Stores
import { useInvitedCreatorsStore } from "@stores/invitedCreators";

// Components
import AvatarWithFallback from "@components/AvatarWithFallback.vue";
import CollabCompTags from "../CollabCompTags.vue";
import CollabExpectationsList from "@views/Creator/CollabExpectationsList.vue";
import CollabInputsSummary from "../CollabInputsSummary.vue";
import CollabMessagesThread from "../CollabMessagesThread/index.vue";
import CollabStatusBar from "../CollabStatusBar.vue";
import ContactUser from "@components/Modals/ContactUser.vue";
import CreateCollabModal from "../CreateCollab/index.vue";
import NoteWithIcon from "@components/NoteWithIcon.vue";
import SideFlyout from "@components/ui/SideFlyout.vue";
import SrpButton from "@components/ui/SrpButton.vue";
import SrpDetailsSummary from "@components/ui/SrpDetailsSummary.vue";
import SrpModal from "@components/ui/SrpModal.vue";
import VisitCheckList from "./VisitCheckList/index.vue";
import { mapState, mapStores } from "pinia";
import LinkWithIcon from "@components/LinkWithIcon.vue";
import linkifyStr from "linkify-string";
import { useUserProfileStore } from "@stores/userProfileStore";
import IconEmbedded from "@components/ui/IconEmbedded.vue";
import VisitPlanApprovalModal from "@views/PaidCollab/CollabSummary/VisitCheckList/VisitPlanApprovalModal.vue";
import ContactShrpaAndEmails from "@views/PaidCollab/CollabSummary/ContactShrpaAndEmails.vue";
import { getGlobalRemoteLogger } from "@helpers/RemoteLogger";

enum ChecklistState {
  Done,
  Current,
  Future,
}

export default defineComponent({
  name: "CollabSummary",

  components: {
    ContactShrpaAndEmails,
    VisitPlanApprovalModal,
    IconEmbedded,
    LinkWithIcon,
    AvatarWithFallback,
    CollabCompTags,
    CollabExpectationsList,
    SrpDetailsSummary,
    CollabMessagesThread,
    CollabStatusBar,
    ContactUser,
    CreateCollabModal,
    NoteWithIcon,
    SideFlyout,
    SrpButton,
    CollabInputsSummary,
    SrpModal,
    VisitCheckList,
  },

  props: {
    communityId: { type: String, required: true },
    collabInput: { type: Object as () => CollabInput, required: true },
    collabIndex: { type: Number, required: true },
    collabRemainingCounts: { type: Object as () => CustomerCollabRemainingCounts | null, required: false, default: null },
    canEditInputs: { type: Boolean, required: true },
    currentDateLocal: { type: Date, default: new Date() },
    // Passed in so we don't re-load each time this component is rendered
    // eslint-disable-next-line
    communityCreatorMatchSummary: { required: true },
  },

  provide() {
    return {
      msgForMessagesThread: this.messageForMessagesThread,
      setMsgForMessagesThread: this.setMsgForMessagesThread,
    };
  },

  emits: {
    scrollToCreators: () => true,
    deleted: () => true,
    next: () => true,
    "update:collabInput": (value: CollabInput) => true,
  },

  data() {
    return {
      globalLog: inject("globalLog") as any,
      screenSize: inject("screenSize") as ScreenSize,

      isDescriptionFullyVisible: false,

      // @ts-ignore
      contentBaseUri: globalThis.Bootstrap.Config.contentCdnBaseUri,

      daysToApplyAfterPosted: CollabConsts.CollabInviteExpirationInDays,

      checklistRows: [] as Array<CollabChecklistRow>,
      isSaving: false,
      saveStatus: null as string | null,
      showRequired: false,
      showDeleteModal: false,
      isMessagesVisible: false,
      messageForMessagesThread: { msg: "" }, // this field allows to send message from the child components (e.g. visit plan review modal)
      showSendToCreatorsModal: false,
      showEditCollabWizard: false,
      openingEditFlow: 1,
      errors: [],

      creatorDetails: null as CollabAssignedCreator,
      // cached after data is loaded
      creatorInput: null as CollabCreatorInput | null,

      newMessagesCount: 0,
      messagesListRefreshTimeoutInSeconds: 180,
      // Only do the message checks for a period of time
      firstMessageCheckTime: null,
      messageCheckStopAfterMinutes: 20,
      messagesListRefreshTimeoutId: null as ReturnType<typeof setTimeout>,
      isMessageThreadImpersonationOn: false,

      compDetailsCollapsed: false,
      lodgingDetailsCollapsed: false,
      isVisitPlanCollapsed: true,

      isCollabDetailsSpoilerOpened: true,

      intersectionObserver: null,
      isButtonsPanelStuck: false,
    };
  },

  computed: {
    ...mapState(useUserProfileStore, ["getViewingUserProfile", "getActingUserProfile", "isSuperOrSalesUser"]),
    ...mapStores(useInvitedCreatorsStore),
    isSendToCreatorsButtonVisible() {
      return !this.collabInput.postedToCreators && !this.collabInput.readyToPost;
    },
    isManagingButtonsPanelVisible() {
      return !this.isCompleted && (this.allowDelete || this.errors.length > 0 || this.saveStatus || this.isSendToCreatorsButtonVisible);
    },
    // Only applies for application flow currently
    daysUntilCommunityIsNotified(): number {
      if (!this.collabInput?.postedToCreators) return -1;

      let postedDate = new Date(this.collabInput.postedToCreators);
      let notifyDate = DateUtils.addDays(postedDate, this.daysToApplyAfterPosted);
      let daysBetween = DateUtils.daysBetween(new Date(), notifyDate);
      var roundedDays = Math.round(daysBetween);
      return roundedDays;
    },
    adventuresAreCreated(): boolean {
      // Note: Also in a method below
      if (!this.collabInput?.collaboration?.status) {
        return false;
      }
      return +this.collabInput.collaboration.status >= +CollabStatuses.AdventuresCreated;
    },
    isCompleted(): boolean {
      const complete = this.collabInput.collaboration && this.collabInput.collaboration.status === CollabStatuses.CompleteStatusId;
      return complete;
    },
    allowDelete(): boolean {
      // Note: A user can delete even their first collab now
      if (!this.collabInput || !this.collabInput.collaboration.status) {
        return true;
      }
      if (this.collabInput.collaboration.status.length === 0) {
        return true;
      }
      // If they haven't sent any invites they can delete it
      if (+this.collabInput.collaboration.status < +CollabStatuses.CreatorsInvitedStatusId) {
        return true;
      }

      return false;
    },
  },

  watch: {
    // Resets the state since they changed collabs
    collabIndex: function (newVal, oldVal) {
      // this.globalLog.info(`CollabIndex Change: ${oldVal} to ${newVal}`);
      this.resetData();
      this.checkForNewMessages();

      this.intersectionObserver?.disconnect();
      this.$nextTick(this.setIntersectionObservers);
    },
    isCollabDetailsSpoilerOpened() {
      this.intersectionObserver?.disconnect();
      this.$nextTick(this.setIntersectionObservers);
    },

    "collabInput.collaboration.creatorId": function (newVal, oldVal) {
      this.globalLog.info(`Summary creatorId Change: Old=${oldVal}, New=${newVal}`);
      if (newVal !== oldVal) this.resetData();
    },
    currentDateLocal: function (newVal, oldVal) {
      if (this.collabInput) {
        this.globalLog.info(`Simulated Date Change: Old=${oldVal}, New=${newVal}`);
        this.calculateChecklist();
      }
    },
  },

  async mounted() {
    await this.resetData();
    await this.checkForNewMessages();
    this.checkForShowMessageCreator();

    await this.setIntersectionObservers();
  },

  beforeUnmount() {
    clearTimeout(this.messagesListRefreshTimeoutId);
    this.intersectionObserver?.disconnect();
  },

  methods: {
    linkifyStr,
    setMsgForMessagesThread(msg: string): void {
      this.messageForMessagesThread.msg = msg;
    },
    async setIntersectionObservers(): Promise<void> {
      // Detect whether button panels are stuck (is "sticky" position applied) to move it a bit higher to the top to avoid overlapping with mobile bottom navigation panel
      this.intersectionObserver = new IntersectionObserver(
        ([e]) => (this.isButtonsPanelStuck = e.intersectionRatio === 0 && (this.$refs.intersectionDetectionTarget as HTMLDivElement)?.getBoundingClientRect().top >= 0),
        { threshold: 0.1 }
      );
      await this.$nextTick();
      if (this.$refs.intersectionDetectionTarget) {
        this.intersectionObserver.observe(this.$refs.intersectionDetectionTarget as Element);
      }
    },
    async resetData(): Promise<void> {
      this.saveStatus = null;
      this.defaultCollapsed();
      await this.load();
      this.calculateChecklist();
      this.defaultChecklistCollapsed();
    },
    async load() {
      // Load the creator details for this collab
      const uri = `${import.meta.env.VITE_API_URL}/collabs/${this.communityId}/inputs/${this.collabInput.id}/creator`;
      const { data } = await axios.get(uri);
      this.creatorDetails = data.creatorDetails;
      this.creatorInput = data.collabCreatorInput;
    },
    async save() {
      this.isSaving = true;
      this.saveStatus = "Saving...";
      const uri = `${import.meta.env.VITE_API_URL}/collabs/${this.communityId}/inputs/${this.collabInput.id}`;
      const { data } = await axios.put<CollabInput>(uri, this.collabInput);
      // emit the updated collabInput
      this.$emit("update:collabInput", data);
      // wait for a render tick for the prop to be updated
      await this.$nextTick();
      // Recaclulate the checklist
      this.calculateChecklist();
      this.isSaving = false;
      this.saveStatus = "Saved!";
      setTimeout(() => (this.saveStatus = null), 5000);
    },
    openEditWizard() {
      this.errors = [];
      this.showEditCollabWizard = true;
      // A workaround to bypass the intro screen
      this.openingEditFlow++;
    },
    checkForShowMessageCreator() {
      // This allows us to build a link (ex. for emails) that sends the user here and pops up the messages panel
      if (RouteHelper.getHashParam("messages") === "true") {
        // Note: AllCollabs.vue has some special logic for this
        this.showMessageThread();
        location.hash = location.hash.replace("messages=true", "messages=false");
      }
      // Old code
      if (RouteHelper.getHashParam("contactCreator") === "true") {
        // Note: AllCollabs.vue has some special logic for this
        this.showMessageThread();
        location.hash = location.hash.replace("contactCreator=true", "contactCreator=false");
      }
    },
    defaultCollapsed() {
      // If a creator is selected or we're in the new wizard flow, then this will be the default pane open, until Adventures were created
      this.globalLog.info(`CollabSummary defaultCollapsed: ${this.collabInput.collaboration.creatorId}`);
      const canShowCorrections = CollabStateHelper.canShowCorrectionsUI(this.collabInput);
      // const creatorIsSelected = this.collabInput.collaboration.creatorId && this.collabInput.collaboration.creatorId.length > 0;
      // Note: This shows always now (even after sending to creators), could reduce that I suppose if needed...
      if (!canShowCorrections) {
        this.isCollabDetailsSpoilerOpened = true;
      } else {
        this.isCollabDetailsSpoilerOpened = false;
      }
    },
    defaultChecklistCollapsed() {
      // If completed or there's no creator chosen, always collapse them
      if (this.collabInput.collaboration.completedDate != null || !this.creatorInput) {
        this.lodgingDetailsCollapsed = true;
        this.compDetailsCollapsed = true;
        this.isVisitPlanCollapsed = true;
        return;
      }

      this.lodgingDetailsCollapsed = this.collabInput.hotelIsBooked || this.collabInput.hotelNightsCovered === 0;
      this.compDetailsCollapsed = this.collabInput.bigTicketItemsAllBooked || this.collabInput?.compedActivitiesTags?.length === 0;
      // If they haven't signed off on the visit plan
      if (this.creatorInput && this.creatorInput?.visitPlanSentDate && !this.creatorInput?.visitPlanSignOffDate) {
        this.isVisitPlanCollapsed = false;
      }
    },

    calculateChecklist() {
      const checklist = [];
      // --Collab Guidance--
      // We assume this is complete by the time they get here (since the wizard should have them fill it out)
      checklist.push(this.createChecklistRow("Fill out Visit Guidance", null, ChecklistState.Done));

      // --Send to Creators--
      if (this.collabInput.readyToPost || this.creatorInput) {
        let text = "Sent to Creators";
        let description = null;
        if (this.collabInput.readyToPost) {
          text += ` on ${moment(this.collabInput.readyToPost).format("MMM Do")}`;
          if (!this.collabInput.postedToCreators) {
            description = "Shrpa is reviewing and will post shortly!";
          } else {
            const applyEndDate = DateUtils.addDays(new Date(this.collabInput.postedToCreators), CollabConsts.CollabInviteExpirationInDays);
            description = `Creators can apply until ${moment(applyEndDate).format("MMM Do")}`;
          }
        }
        checklist.push(this.createChecklistRow(text, description, ChecklistState.Done));
      } else {
        checklist.push(this.createChecklistRow("Send to Creators", null, ChecklistState.Current));
      }

      // --Creators Apply--
      const creatorInviteEndDate = this.getCreatorInviteEndDate();
      const creatorsApplied = +this.collabInput.collaboration.status >= +CollabStatuses.CreatorsAcceptedStatusId;
      const creatorApplicationWindowOpen = creatorInviteEndDate && creatorInviteEndDate > this.currentDate();
      // Show "Creators Applying" as current if the window is still open OR if nobody applied.
      // NOTE: In the future we might want to show a message for them to reach out to us (normally we should be reaching out to them anyway).
      const showCreatorsApplyingState = creatorApplicationWindowOpen || !creatorsApplied;
      if (!this.collabInput.postedToCreators && !this.creatorInput) {
        checklist.push(this.createChecklistRow("Creators can Apply", null, ChecklistState.Future));
      } else {
        const description = `Creators can apply until ${moment(creatorInviteEndDate).format("MMM Do")}`;
        if (showCreatorsApplyingState && !this.creatorInput) {
          checklist.push(this.createChecklistRow("Creators Applying", description, ChecklistState.Current));
        } else {
          checklist.push(this.createChecklistRow("Creators Applied", description, ChecklistState.Done));
        }
      }

      // --Choose Creator--
      const collabIsComplete = this.collabInput.collaboration && this.collabInput.collaboration.status === CollabStatuses.CompleteStatusId;
      const creatorHasConfirmed = this.creatorInput?.creatorConfirmedWillWork === true;
      if (this.creatorInput) {
        if (creatorHasConfirmed || collabIsComplete) {
          // Creator was chosen and has confirmed
          let text = "Creator Chosen";
          if (this.collabInput.collaboration.creatorChosenDate) {
            text += ` on ${moment(this.collabInput.collaboration.creatorChosenDate).format("MMM Do")}`;
          }
          checklist.push(this.createChecklistRow(text, "Get in touch with the creator to coordinate details", ChecklistState.Done));
        } else {
          // Creator still needs to confirm
          checklist.push(this.createChecklistRow("Awaiting Creator Confirm", "Waiting for the creator to confirm their availability", ChecklistState.Current));
        }
      } else {
        if (!this.collabInput.readyToPost || showCreatorsApplyingState) {
          checklist.push(this.createChecklistRow("Choose a Creator", null, ChecklistState.Future));
        } else {
          const communityChooseCreatorDate = creatorInviteEndDate ? DateUtils.addDays(creatorInviteEndDate, CollabConsts.DaysAfterCreatorsDoneApplyingCommunityToChoose) : null;
          const isUrgent = !showCreatorsApplyingState && communityChooseCreatorDate && communityChooseCreatorDate < this.currentDate();
          const description = isUrgent ? "Choose soon to lock in the date with a creator!" : `You have until ${moment(communityChooseCreatorDate).format("MMM Do")} to choose a creator`;
          checklist.push(this.createChecklistRow("Choose a Creator", description, ChecklistState.Current, isUrgent));
        }
      }

      const visitDateHasPassed = this.getVisitDateHasPassed();

      // --Lodging and Comped Activities--
      // NOTE: These are special sections that have inline fields
      if (this.canEditInputs) {
        // If inputs are still being edited then these are future (just headings)
        checklist.push(this.createChecklistRow("Visit Plan", null, ChecklistState.Future));
        checklist.push(this.createChecklistRow("Lodging Details", null, ChecklistState.Future));
        checklist.push(this.createChecklistRow("Comped Activities", null, ChecklistState.Future));
      } else {
        // Otherwise show the sections/fields
        const visitDateIsApproaching = this.creatorInput?.proposedVisitDateTime && DateUtils.addDays(new Date(this.creatorInput.proposedVisitDateTime), -7) < this.currentDate();

        // Visit Plan
        let visitPlanCompState = ChecklistState.Current;
        if (this.creatorInput?.visitPlanSignOffDate) visitPlanCompState = ChecklistState.Done;
        else if (!this.creatorInput) visitPlanCompState = ChecklistState.Future;

        const visitPlanRow = this.createChecklistRow("Visit Plan", null, visitPlanCompState, visitPlanCompState === ChecklistState.Current && visitDateIsApproaching && !visitDateHasPassed);
        visitPlanRow.isVisitPlanSection = true;
        checklist.push(visitPlanRow);

        // Lodging and Comped Activities
        const lodgingIsComplete = this.collabInput.hotelIsBooked || this.collabInput.hotelNightsCovered === 0;
        let lodgingState = lodgingIsComplete ? ChecklistState.Done : ChecklistState.Current;
        const willCompActivities = this.collabInput?.compedActivitiesTags?.length > 0;
        const compActivitiesIsComplete = this.collabInput.bigTicketItemsAllBooked || !willCompActivities;
        let compState = compActivitiesIsComplete ? ChecklistState.Done : ChecklistState.Current;
        /* if (!creatorHasConfirmed) {
          //Don't let them mark complete if the creator hasn't confirmed yet.
          lodgingState = ChecklistState.Current;
          compState = ChecklistState.Current;
        }*/
        if (!this.creatorInput) {
          // Force these to future if no creator is selected
          lodgingState = ChecklistState.Future;
          compState = ChecklistState.Future;
        }

        const lodgingRow = this.createChecklistRow("Lodging Details", null, lodgingState, lodgingState === ChecklistState.Current && visitDateIsApproaching);
        lodgingRow.isLodgingSection = true;
        checklist.push(lodgingRow);
        const compedActivityRow = this.createChecklistRow("Comped Activities", null, compState, compState === ChecklistState.Current && visitDateIsApproaching);
        compedActivityRow.isCompedActivitySection = true;
        checklist.push(compedActivityRow);
      }

      // Creator Visit
      const visitDateIsNear = this.getVisitDateIsNear();
      const visitDateString = this.getVisitDateString();
      let creatorVisitText = "Creator Visit";
      if (visitDateString) {
        creatorVisitText += ` (${visitDateString})`;
      }
      let creatorVisitState = visitDateHasPassed ? ChecklistState.Done : ChecklistState.Future;
      if (visitDateIsNear && !visitDateHasPassed) {
        creatorVisitState = ChecklistState.Current;
      }
      const creatorVisitDescription = visitDateHasPassed ? "Your adventures are being created!" : null;
      checklist.push(this.createChecklistRow(creatorVisitText, creatorVisitDescription, creatorVisitState));

      // --Adventure Review--
      let adventuresAreCreated = false;
      if (this.collabInput.collaboration?.status) {
        // Must on or past the AdventureCreated Status and Shrpa must have reviewed the adventures
        adventuresAreCreated = +this.collabInput.collaboration.status >= +CollabStatuses.AdventuresCreated && this.collabInput.correctionsStatus !== CollabStatuses.CorrectionStatusShrpaReview;
      }
      const adventuresReadyDate = this.getAdventuresReadyDate();
      if (collabIsComplete || adventuresAreCreated) {
        let text = null;
        let description = null;
        if (collabIsComplete) {
          text = "Creator Visit Complete!";
          description = " ";
        } else {
          // Note: This pane collapses once adventures are created so this isn't very discoverable.
          // We should probably move this down to the adventure review panel eventually.
          text = "Review your Adventures";
          if (this.collabInput.correctionsStatus === CollabStatuses.CorrectionStatusShrpaReview) {
            description = "Shrpa is reviewing your adventures for quality";
          } else if (this.collabInput.correctionsStatus === CollabStatuses.CorrectionStatusPendingCreator) {
            description = "The creator is currently making corrections";
          } else {
            const firstApprovedDate = this.collabInput.collaboration?.notifications?.firstApproveNotification;
            const autoCompleteDate = firstApprovedDate ? DateUtils.addDays(new Date(firstApprovedDate), CollabConsts.DaysCommunityHasToReviewAdventures) : null;
            if (autoCompleteDate && autoCompleteDate > this.currentDate()) {
              // Tell them when we'll complete the collab and adventures will go live
              description = `Adventures will go live on ${moment(autoCompleteDate).format("MMM Do")}`;
            } else {
              // If the date has passed
              description = "Check it out! Your adventures are ready.";
            }
          }
        }
        let isUrgent = false;
        if (!collabIsComplete && adventuresReadyDate) {
          isUrgent = DateUtils.addDays(adventuresReadyDate, 2) < this.currentDate();
        }
        checklist.push(this.createChecklistRow(text, description, collabIsComplete ? ChecklistState.Done : ChecklistState.Current, isUrgent));
      } else {
        let text = "Adventure Review";
        if (adventuresReadyDate) {
          text += ` (~${moment(adventuresReadyDate).format("MMM Do")})`;
        }
        checklist.push(this.createChecklistRow(text, null, ChecklistState.Future));
      }

      // --Upcoming Actions Header--
      // Calculate where it should go
      let futureHeadingIndex = -1;
      // We keep track of this since the rule is to only show the LAST description (that isn't in a future step)
      let lastDescriptionIndexBeforeFuture = -1;
      for (let i = 0; i < checklist.length; i++) {
        const row = checklist[i];
        if (row.isFuture && futureHeadingIndex < 0) {
          // Just set it once
          futureHeadingIndex = i;
        }
        // Force everything after it to future (in case there's a bug in the logic above)
        if (futureHeadingIndex >= 0) row.isFuture = true;
        // Last description (if not future)
        if (row.description && futureHeadingIndex < 0) lastDescriptionIndexBeforeFuture = i;
      }
      if (futureHeadingIndex >= 0) {
        const futureHeadingRow = this.createChecklistRow("Upcoming Actions", null, ChecklistState.Future);
        futureHeadingRow.isFutureActionsHeading = true;
        checklist.splice(futureHeadingIndex, 0, futureHeadingRow);
      }
      // Null out the descriptions for all but the lastDescriptionIndexBeforeFuture
      if (lastDescriptionIndexBeforeFuture >= 0) {
        checklist.forEach((row, i) => {
          if (i !== lastDescriptionIndexBeforeFuture) row.description = null;
        });
      }

      // Set out the checklist
      this.checklistRows = checklist;
    },
    createChecklistRow(text: string, description: string | null, state: ChecklistState, isUrgent = false): CollabChecklistRow {
      return {
        text: text,
        description: description,
        isDone: state === ChecklistState.Done,
        isFuture: state === ChecklistState.Future,
        isUrgent: state === ChecklistState.Current && isUrgent, // Doesn't make sense for future or completed
        isFutureActionsHeading: false,
        isLodgingSection: false,
        isCompedActivitySection: false,
        isVisitPlanSection: false,
      };
    },
    currentDate(): Date {
      // @ts-ignore
      return this.currentDateLocal;
    },
    getVisitDateString(): string | null {
      if (this.creatorInput) {
        return DateUtils.formatDateRange(this.creatorInput.proposedVisitDateTime, this.creatorInput.numberOfDaysStaying, false);
      }
      return null;
    },
    getVisitDateIsNear(): boolean {
      if (this.creatorInput) {
        // Make this active shortly before
        const visitNearDate = DateUtils.addDays(new Date(this.creatorInput.proposedVisitDateTime), -3);
        return visitNearDate < this.currentDate();
      }
      return false;
    },
    getVisitDateHasPassed(): boolean {
      if (this.creatorInput) {
        // Add 1 day since we don't have good time precision on this
        const visitEndDate = DateUtils.addDays(new Date(this.creatorInput.proposedVisitDateTime), this.creatorInput.numberOfDaysStaying + 1);
        return visitEndDate < this.currentDate();
      }
      return false;
    },
    getCreatorInviteEndDate(): Date | null {
      let postDate = this.collabInput.readyToPost;
      if (this.collabInput.postedToCreators) {
        postDate = this.collabInput.postedToCreators;
      }
      if (postDate) {
        const inviteEnd = DateUtils.addDays(new Date(postDate), CollabConsts.CollabInviteExpirationInDays);
        return inviteEnd;
      }
      return null;
    },
    getAdventuresReadyDate(): Date | null {
      if (this.creatorInput) {
        let adventuresReadyDate = DateUtils.addDays(new Date(this.creatorInput.proposedVisitDateTime), this.creatorInput.numberOfDaysStaying);
        adventuresReadyDate = DateUtils.addDays(adventuresReadyDate, CollabConsts.DaysCreatorHasToCreateAdventures);
        return adventuresReadyDate;
      }
      return null;
    },

    showMessageThread() {
      if (this.isSuperOrSalesUser !== true) {
        // Clear out message count since we're about to show them the messages, which will zero it out.
        this.newMessagesCount = 0;
      }
      this.isMessagesVisible = true;
    },

    setNewMessagesCheckTimeout(): void {
      clearTimeout(this.messagesListRefreshTimeoutId);
      // Only run message checks for a set period (it has dimishing returns and browsers now memory optimize tabs)
      if (this.firstMessageCheckTime) {
        const timeDifference = new Date().getTime() - this.firstMessageCheckTime.getTime();
        const minutesDifference = timeDifference / (1000 * 60);
        if (minutesDifference > this.messageCheckStopAfterMinutes) {
          getGlobalRemoteLogger().info(
            `MaxMessageCheckTime customer for user=${this.getActingUserProfile.sherpaId}, collab=${this.collabInput.id}, thread=${this.collabInput.collaboration.messageThreadId}, minutes=${minutesDifference}`
          );
          return;
        }
      }
      this.messagesListRefreshTimeoutId = setTimeout(() => this.checkForNewMessages(), this.messagesListRefreshTimeoutInSeconds * 1000);
    },
    async checkForNewMessages(): Promise<void> {
      clearTimeout(this.messagesListRefreshTimeoutId);

      // Messaging buttons are hidden once the collab is complete
      if (this.collabInput.collaboration.completedDate) return;

      // Note: Not sending senderIdInContext= param here (since we don't really have that, it's just contactEmail)
      // @ts-ignore
      const { data } = await axios.get(`${import.meta.env.VITE_API_URL}/messaging/${this.collabInput.collaboration.messageThreadId}/new-count`);

      this.newMessagesCount = data;
      if (!this.firstMessageCheckTime) {
        this.firstMessageCheckTime = new Date();
      }

      this.setNewMessagesCheckTimeout();
    },

    // NOTE: Code previously in CommunityInput.vue
    isPastInputStatus(): boolean {
      return this.collabInput.collaboration.status !== CollabStatuses.CommunityInputStatusId;
    },
    askToDelete() {
      this.showDeleteModal = true;
    },
    cancelDelete() {
      this.showDeleteModal = false;
    },
    askToSendToCreators() {
      if (this.validate()) {
        this.showSendToCreatorsModal = true;
      }
    },
    async cancelSendToCreators() {
      this.showSendToCreatorsModal = false;
    },
    async deleteCollab() {
      this.saveStatus = "Deleting...";
      const uri = `${import.meta.env.VITE_API_URL}/collabs/${this.communityId}/inputs/${this.collabInput.id}`;
      await axios.delete(uri);
      this.saveStatus = null;
      this.$emit("deleted");
      this.showDeleteModal = false;
    },
    validate(): boolean {
      const result = CollabInputValidator.validateCollabInputs(this.collabInput, null, this.isSuperOrSalesUser, this.isSuperOrSalesUser); // Validate all steps
      this.errors = result.errors;
      return this.errors.length === 0;
    },
    async sendToCreators() {
      // Validate before we update status, save, and progress
      if (this.validate()) {
        // Set that they posted this collab to creators
        // NOTE! We introduced an approval step here so we have eyes on the collab before it's posted
        if (!this.collabInput.readyToPost) {
          this.collabInput.readyToPost = new Date().toISOString();
        } else if (this.isSuperOrSalesUser) {
          // Currently only superusers can set this
          // and we make them mark as ready first so we exercise that code flow
          this.collabInput.postedToCreators = new Date().toISOString();
        }

        if (this.collabInput.collaboration.status === CollabStatuses.CommunityInputStatusId) {
          // Note: The save endpoint just updates the CollabInput (not Collaboration), but status is an exception to that (see server code)
          // This jumps straight to Invited status since the community doesn't select like in the old/retired manuallyInviteCreators below
          this.collabInput.collaboration.status = CollabStatuses.CreatorsInvitedStatusId;
        }
        await this.save();
        this.showSendToCreatorsModal = false;
        // this.isCollabDetailsSpoilerOpened = false; //Not collapsing now that we have the checklist
        this.$emit("next");
        // Re-calculate the checklist since this shifts the state
        this.calculateChecklist();
      }
    },
  },
});
</script>

<style scoped lang="scss">
@import "@/scss/variables.scss";
@import "../AllCollabsV2.scss";

// Creator name link ==========================================================
.creator-name-link {
  font-weight: 400;
  text-decoration: underline !important;
  text-decoration-color: rgba(0, 0, 0, 0.15) !important;
  text-underline-offset: 4px !important;
  text-decoration-thickness: 1px !important;
  word-break: break-word;

  &:hover {
    text-decoration: none !important;
    opacity: 0.75;
  }
}

// Show more link =============================================================
.show-more-link {
  display: inline-flex;
  position: relative;
  cursor: pointer;
  user-select: none;

  // increases clickable area
  &::before {
    content: "";
    width: calc(100% + 15px);
    height: calc(100% + 15px);
    position: absolute;
    inset: 50% auto auto 50%;
    transform: translate(-50%, -50%);
  }

  &__text {
    display: inline-flex;
    color: rgb(4, 102, 103);
    text-decoration: underline;
    text-decoration-color: rgba(5, 133, 135, 0.3);
    text-underline-offset: 3px;
    text-decoration-thickness: 1px;
  }

  &:hover &__text {
    text-decoration: none;
  }
}

.ui.form {
  padding: 20px;
  box-shadow: 0 0 5px 0 #ccc;
  > .field > label.section-title {
    font-size: 18px !important;
    margin: 15px 0 5px 0 !important;
  }
  .btn-groups {
    text-align: right;
  }
}

.creator-image {
  height: 90px;
  max-height: 20vw;
  margin-right: 10px;
  flex-shrink: 0;
}

//Override the default semantic min-height
.ui.form textarea {
  min-height: 60px;
  //Default height (override inline on some)
  height: 80px;
}

@media screen and (max-width: 420px) {
  .ui.form {
    padding: 15px;
    h1 {
      font-size: 18px !important;
    }
    > .field > label.section-title {
      font-size: 16px !important;
      margin: 0 !important;
    }
  }
}

.ui.header.small.future-tasks {
  padding: 8px 0px 8px 16px;
  margin: 1rem 0 0.25rem 0;
}

.collab-checklist {
  padding: 16px 0px 8px 0px !important;
  .checklist-section {
    padding: 0px 16px 4px 16px;
    // display: flex;
    align-items: center;
    margin-bottom: 0.5rem;
    &.active {
      background-color: $brand-color-lightest;
      padding: 8px 16px 16px 16px;
    }
    &.expandable {
      display: block;
      .full-header-wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 0.5rem;
      }
    }
    .main-header-wrapper {
      display: flex;
      align-items: center;
    }
    .main-header-wrapper-desc {
      margin-top: 0.5rem;
      margin-left: 1.75rem;
    }
    &.future {
      display: block;
      .main-header-wrapper {
        padding: 0px 16px 0px 0px;
        .ui.header {
          color: rgba(0, 0, 0, 0.25);
        }
        i {
          color: rgba(0, 0, 0, 0.25);
        }
      }
    }
    &.urgent {
      .ui.header {
        color: #ec563b;
      }
      i {
        color: #ec563b;
      }
    }
    .ui.header {
      margin-top: 0.2rem !important;
      margin-left: 0.25rem;
    }
  }
}

.large-accordion-dropdown {
  display: flex;
  justify-content: flex-start !important;
  align-items: center;
  position: relative !important;
  z-index: 5;
  cursor: pointer;
  user-select: none;

  &:hover {
    opacity: 0.8;
  }

  &::before {
    content: "";
    width: calc(100% + 17px);
    height: calc(100% + 17px);
    position: absolute;
    inset: 50% auto auto 50%;
    transform: translate(-50%, -50%);
  }

  h2 {
    height: 30px;
    margin: 0;
    border-bottom: 1px transparent solid;
    display: inline-block;
    transition:
      border-color 0.12s ease-in-out,
      opacity 0.12s ease-in-out;
  }

  &:hover h2 {
    text-decoration: underline;
    text-decoration-color: rgba(0, 0, 0, 0.15);
    text-underline-offset: 4px;
    text-decoration-thickness: 1px;
  }
}
</style>
