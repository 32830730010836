const contentBaseUri = globalThis.Bootstrap?.Config.contentCdnBaseUri;

export default {
  // Checks if this file is supported for upload
  // Returns an error if NOT supported or null if it's supported
  isFileSupportedForUpload(fileName: string, fileType: string, fileSize: number): string {
    // Note: There are also a few one-off checks below

    // July 2024 UPDATE: Switching over to a whitelist
    const supportedFileTypes = [".mp4", ".mov", ".jpg", ".jpeg", ".png", ".webp"];
    // If it's not in the supported list, tell the user that a file extension is not supported
    const validExt = supportedFileTypes.find(ext => fileName?.toLowerCase().endsWith(ext));
    // Get the extension of the file
    if (!validExt) {
      const ext = fileName.split(".").pop();
      return ext + " files are not supported";
    }
    // Old: We don't currently support svg files server-side (the packages that convert tend to rely on System.Drawing whose support is sketchy server-side)
    /* if (fileName?.toLowerCase().endsWith(".svg") == true || fileType === "image/svg+xml") {
      return ".svg files not supported";
    }*/

    return null;
  },

  isVideoFileType(fileName: string, fileType: string | null = null): boolean {
    if (fileName?.toLowerCase().endsWith(".mp4")) return true;
    if (fileName?.toLowerCase().endsWith(".mov")) return true;

    if (fileType?.toLowerCase().startsWith("video")) return true;
    else return false;
  },

  getThumbnailPath(imageOrVideoId: string): string {
    return this.getAdventureImagePath(imageOrVideoId, true);
  },
  getAdventureImagePath(imageOrVideoId: string, useThumbnail = false): string {
    if (!imageOrVideoId) return "missing-image.jpg";
    if (this.isVideoFileType(imageOrVideoId)) {
      return `${contentBaseUri}/cms/images/expeditions/${useThumbnail ? "thumbnocrop/" : ""}${imageOrVideoId.split(".")[0]}.jpg`;
    } else {
      return `${contentBaseUri}/cms/images/expeditions/${useThumbnail ? "thumbnocrop/" : ""}${imageOrVideoId}`;
    }
  },
};
