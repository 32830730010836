<template>
  <div
    :class="{
      'textarea-subtle': true,
      'textarea-subtle--no-right-padding': !props.isWithEditButton,
    }"
    @click="isNameEditMode = true"
  >
    <!-- This block is needed to get the size of the block with no clamped lines.
          That's the only way to detect is there some hidden text when lines are clamped -->
    <div
      class="global-h2 textarea-subtle__text-overlay textarea-subtle__text-overlay--position-absolute"
      :style="`
        min-height: ${props.fontSize * props.lineHeightCoeff}px;
        font-size: ${props.fontSize}px;
        font-weight: ${props.fontWeight};
        font-family: ${props.fontFamily};
        line-height: ${props.fontSize * props.lineHeightCoeff}px;
        text-align: ${props.isTextAlignCenter ? 'center' : 'left'};
      `"
      ref="domRefTextBlockNotClamped"
    >
      {{ textareaValue || props.placeholder }}
    </div>
    <div
      class="global-h2 textarea-subtle__text-overlay"
      :style="`
        min-height: ${props.fontSize * props.lineHeightCoeff}px;
        font-size: ${props.fontSize}px;
        font-weight: ${props.fontWeight};
        font-family: ${props.fontFamily};
        line-height: ${props.fontSize * props.lineHeightCoeff}px;
        text-align: ${props.isTextAlignCenter ? 'center' : 'left'};
        line-clamp: ${props.lineClamp ? props.lineClamp : 'none'} !important;
        -webkit-line-clamp: ${props.lineClamp ? props.lineClamp : 'none'} !important;
      `"
      ref="domRefTextClamped"
    >
      {{ textareaValue || props.placeholder }}
    </div>

    <textarea
      :class="{
        'global-h2': true,
        'textarea-subtle__textarea': true,
        'textarea-subtle__textarea--disabled': props.isDisabled,
        'textarea-subtle__textarea--always-visible-border': props.isBorderAlwaysVisible,
        'textarea-subtle__textarea--no-dotted-underline': !props.isWithUnderline,
        'textarea-subtle__textarea--no-border-and-bg': Boolean(props.fakeBgSize),
        'textarea-subtle__textarea--full-width': !props.isWithEditButton,
        'textarea-subtle__textarea--same-height-as-overlay': props.isDisabled,
      }"
      type="text"
      v-model="textareaValue"
      @blur="isNameEditMode = false"
      ref="domRefTextarea"
      :placeholder="props.placeholder"
      :disabled="props.isDisabled"
      :style="{
        minHeight: `${props.fontSize * props.lineHeightCoeff}px`,
        fontSize: `${props.fontSize}px`,
        fontWeight: `${props.fontWeight}`,
        fontFamily: `${props.fontFamily}`,
        lineHeight: `${props.fontSize * props.lineHeightCoeff}px`,
        textAlign: props.isTextAlignCenter ? 'center' : 'left',
      }"
      :autocapitalize="props.autocapitalize"
      :autocorrect="props.autocorrect"
    ></textarea>

    <div
      v-if="Boolean(props.fakeBgSize) && !props.isDisabled"
      :class="{
        'textarea-subtle__fake-bg-wrap': true,
        'textarea-subtle__fake-bg-wrap--full-width': !props.isWithEditButton,
        'textarea-subtle__fake-bg-wrap--compensate-left-padding': props.isTextAlignCenter,
      }"
    >
      <div
        :class="{
          'textarea-subtle__fake-bg': true,
          'textarea-subtle__fake-bg--error': props.isError,
        }"
        :style="{
          ...props.fakeBgSize,
          inset: props.fakeBgInset ? props.fakeBgInset : 'unset',
        }"
      ></div>
    </div>

    <LinkWithIcon
      v-if="props.isWithEditButton && !props.isDisabled"
      :class="{
        'textarea-subtle__edit-icon': true,
        'textarea-subtle__edit-icon--invisible': isNameEditMode,
      }"
      @click="
        () => {
          domRefTextarea.focus();
          isNameEditMode = true;
        }
      "
    >
      <IconEmbedded name="edit-pencil_2" :size="28" color="rgba(0, 0, 0, 0.5)" />
    </LinkWithIcon>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted, watch, onBeforeUnmount, nextTick } from "vue";

// Components
import LinkWithIcon from "@components/LinkWithIcon.vue";
import IconEmbedded from "@components/ui/IconEmbedded.vue";

const props = withDefaults(
  defineProps<{
    fontSize?: number;
    fontWeight?: number;
    fontFamily?: string;
    isDisabled?: boolean;
    isWithEditButton?: boolean;
    placeholder?: string;
    isBorderAlwaysVisible?: boolean;
    fakeBgSize?: { width?: string; minWidth?: string; height?: string } | null;
    fakeBgInset?: string;
    isWithUnderline?: boolean;
    lineHeightCoeff?: number;
    isTextAlignCenter?: boolean;
    isError?: boolean;
    lineClamp?: number | "none";
    autocapitalize?: string;
    autocorrect?: string;
  }>(),
  {
    fontSize: 14,
    fontWeight: 600,
    fontFamily: "'Quicksand', sans-serif",
    isDisabled: false,
    isWithEditButton: true,
    placeholder: "Subtle textarea",
    isBorderAlwaysVisible: false,
    fakeBgSize: null,
    fakeBgInset: "",
    isWithUnderline: false,
    lineHeightCoeff: 1.2,
    isTextAlignCenter: false,
    isError: false,
    lineClamp: 0,
    autocapitalize: "on",
    autocorrect: "on",
  }
);

const emit = defineEmits<{
  (e: "update:isVisible", value: boolean): void;
  (e: "close"): void;
}>();

const textareaValue = defineModel("value", { type: String, default: "" });

// Local value ================================================================
const isNameEditMode = ref<boolean>(false);
const domRefTextarea = ref<HTMLElement | null>(null);

// Calc the full text container size ==========================================
// when there is the clamped lines there is no straightforward way to detect whether some lines are hidden
const domRefTextBlockNotClamped = ref<HTMLElement | null>(null);
const domRefTextClamped = ref<HTMLElement | null>(null);

const isSomeTextHidden = ref<boolean>(false);

watch(() => [textareaValue.value, props.lineClamp], calcIsSomeTextHidden);

onMounted(() => window.addEventListener("resize", calcIsSomeTextHidden));
onBeforeUnmount(() => window.removeEventListener("resize", calcIsSomeTextHidden));

onMounted(calcIsSomeTextHidden);

async function calcIsSomeTextHidden() {
  await nextTick();

  const notClampedSize = domRefTextBlockNotClamped.value?.getBoundingClientRect().height;
  const clampedSize = domRefTextClamped.value?.getBoundingClientRect().height;

  isSomeTextHidden.value = notClampedSize > clampedSize;
}

// Define expose ==============================================================
defineExpose({
  isSomeTextHidden,
});
</script>

<style scoped lang="scss">
// Textarea subtle ============================================================
.textarea-subtle {
  min-width: 100px;
  padding-right: 35px;
  display: inline-flex;
  position: relative;
  z-index: 0;

  &--no-right-padding {
    padding-right: 0;
  }

  &__text-overlay {
    min-width: calc(100% + 13px);
    padding: 5px 5px 4px 4px;
    margin-left: -4px;
    text-decoration: underline;
    text-decoration-color: rgba(0, 0, 0, 0.5);
    text-underline-offset: 3px;
    text-decoration-thickness: 1px;
    text-decoration-style: dashed;
    word-break: keep-all;
    white-space: pre-wrap;

    display: -webkit-box !important;
    overflow: hidden !important;
    //line-clamp: 2 !important;
    //-webkit-line-clamp: 2 !important;
    text-overflow: ellipsis !important;
    -webkit-box-orient: vertical !important;

    user-select: none;
    cursor: text;
    opacity: 0;
    pointer-events: none;

    &--position-absolute {
      position: absolute;
      inset: 0 auto auto 0;
      opacity: 0;
      user-select: none;
    }
  }

  &__textarea {
    width: calc(100% - 20px);
    height: calc(100% + 5px);
    padding: 5px 0 4px 4px;
    box-sizing: border-box;
    resize: none;
    border: 1px rgba(0, 0, 0, 0) solid;
    border-radius: 4px;
    position: absolute;
    inset: -1px auto auto -5px;
    z-index: -1;
    background: transparent;
    box-shadow: none;
    text-decoration: underline;
    text-underline-offset: 3px;
    text-decoration-thickness: 1px;
    text-decoration-style: dashed;
    word-break: keep-all;
    white-space: pre-wrap;
    transition: none;

    &--full-width {
      width: calc(100% + 13px);
    }

    &--same-height-as-overlay {
      height: 100%;
    }

    &::selection {
      background-color: rgba(0, 200, 255, 0.2);
    }

    &--always-visible-border {
      border-color: rgba(0, 0, 0, 0.2);
    }

    &--no-border-and-bg {
      border-color: rgba(0, 0, 0, 0) !important;
      background-color: rgba(0, 0, 0, 0) !important;
    }

    &--no-dotted-underline {
      text-decoration: none;
    }

    &:hover,
    &:focus {
      border-color: rgba(0, 0, 0, 0.2);
      box-shadow: none;
    }

    &:focus {
      border-color: rgba(0, 0, 0, 0.35);
    }

    &--disabled {
      border-color: rgba(0, 0, 0, 0) !important;
      overflow: hidden;
      cursor: text;
    }

    &:focus {
      background: rgba(255, 255, 255, 1);
    }
  }

  &__fake-bg-wrap {
    width: calc(100% - 20px);
    max-width: calc(100% - 20px);
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    inset: 0 auto auto -5px;
    z-index: -2;
    pointer-events: none;

    &--full-width {
      width: 100%;
      max-width: 100%;
    }

    &--compensate-left-padding {
      width: calc(100% + 16px);
      max-width: calc(100% + 16px);
    }
  }

  &__fake-bg {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    border: 1px rgba(210, 210, 210, 1) solid;
    border-radius: 4px;
    position: relative;
    background: white;

    &--error {
      box-shadow: 0 0 0 5px rgba(188, 73, 73, 0.35);
    }
  }

  &__textarea:focus + &__fake-bg-wrap &__fake-bg {
  }

  &__textarea:hover + &__fake-bg-wrap &__fake-bg,
  &__textarea:focus + &__fake-bg-wrap &__fake-bg {
    border-color: rgba(0, 0, 0, 0.2);
  }

  &__textarea:focus + &__fake-bg-wrap &__fake-bg {
    border-color: rgba(0, 0, 0, 0.35);
  }

  &__edit-icon {
    width: 28px;
    height: 28px;
    position: absolute;
    inset: calc(50% - 14px) -7px auto auto;
    color: rgba(0, 0, 0, 0.5);
    font-size: 28px;
    transition: opacity 0.07s ease-in-out;
    transition-delay: 0ms;

    &--invisible {
      opacity: 0;
      pointer-events: none;
    }
  }
}
</style>
