<template>
  <SrpModal
    :isVisible="props.isVisible"
    @update:isVisible="emit('update:isVisible', false)"
    :isClosable="false"
    :isCloseButtonVisible="true"
    isWithScroll
    :size="isPhoneNumberVerificationFormVisible ? 'small' : 'medium'"
  >
    <template #content>
      <slot />

      <div v-if="!isPhoneNumberVerificationFormVisible" style="margin-bottom: 20px">
        <h2 v-if="Object.keys(slots).length" class="global-h2" style="margin-bottom: 7px">New Collab Notifications</h2>
        <h1 v-else class="global-h1" style="margin-bottom: 7px">New Collab Notifications</h1>

        <p style="margin-bottom: 5px; color: rgba(91, 91, 91, 1); font: 14px/18px sans-serif">How would you like to be notified about new collaboration opportunities in your area?</p>
      </div>

      <PhoneNumberVerificationForm v-if="isPhoneNumberVerificationFormVisible" @verificationCanceled="isPhoneNumberVerificationFormVisible = false" @phoneVerified="emit('update:isVisible', false)" />

      <template v-else>
        <!-- Switches container -->
        <div class="switches-container" style="margin-bottom: 30px">
          <label class="switches-container__switch-line">
            <SrpSwitch class="switches-container__switch" v-model:isChecked="isEmailOn" />
            <span class="global-h5 switches-container__text">Email</span>
          </label>

          <label class="switches-container__switch-line">
            <SrpSwitch class="switches-container__switch" v-model:isChecked="isSMSOn" />
            <span class="global-h5 switches-container__text">Text</span>
          </label>
        </div>
        <!-- / Switches container -->

        <FormErrorsList v-if="!isEmailOn && !isSMSOn" style="margin-bottom: 20px" :errors="['Please choose at least one way to be notified.']" />

        <NoteWithIcon color="blue" size="tiny">
          <template #text>
            <div>Notifications can be changed at any time via My Settings</div>
          </template>
        </NoteWithIcon>
      </template>
    </template>

    <template v-if="!isPhoneNumberVerificationFormVisible" #footer>
      <SrpButton @click="handleConfirmOrContinue" :isDisabled="(!isEmailOn && !isSMSOn) || isSavingProfile">
        {{ isSMSOn ? "Continue" : "Save" }}
        <template v-if="isSMSOn || isSavingProfile" #iconRight>
          <LoadingIcon v-if="isSavingProfile" :size="26" />
          <IconEmbedded v-else name="arrow-right_2-5" :size="26" />
        </template>
      </SrpButton>
    </template>
  </SrpModal>
</template>

<script setup lang="ts">
import { ref, computed, useSlots, watch } from "vue";
import { getGlobalRemoteLogger as globalRemoteLogger } from "@helpers/RemoteLogger";

// Components
import FormErrorsList from "@components/FormErrorsList.vue";
import IconEmbedded from "@components/ui/IconEmbedded.vue";
import NoteWithIcon from "@components/NoteWithIcon.vue";
import PhoneNumberVerificationForm from "@components/PhoneNumberVerificationForm.vue";
import SrpButton from "@components/ui/SrpButton.vue";
import SrpModal from "@components/ui/SrpModal.vue";
import SrpSwitch from "@components/ui/SrpSwitch.vue";

// Stores
import { useUserProfileStore } from "@stores/userProfileStore";
import LoadingIcon from "@components/ui/LoadingIcon.vue";
const userProfileStore = useUserProfileStore();

const props = withDefaults(
  defineProps<{
    isVisible: boolean;
  }>(),
  {
    isVisible: false,
  }
);

const emit = defineEmits<{
  (e: "update:isVisible", value: boolean): void;
  (e: "phoneVerificationFormToggle", isVisible: boolean): void;
}>();

const slots = useSlots();

// Email/SMS switch values ====================================================
const isEmailOn = computed<boolean>({
  get: () => userProfileStore.getViewingUserProfile?.creatorNotifications.newCollaborations.email,
  set: value => (userProfileStore.getViewingUserProfile.creatorNotifications.newCollaborations.email = value),
});
const isSMSOn = computed<boolean>({
  get: () => userProfileStore.getViewingUserProfile?.creatorNotifications.newCollaborations.sms,
  set: value => (userProfileStore.getViewingUserProfile.creatorNotifications.newCollaborations.sms = value),
});

// Show phone verification form ===============================================
const isPhoneNumberVerificationFormVisible = ref<boolean>(false);

// Handle confirm or continue =================================================
const isSavingProfile = ref<boolean>(false);

async function handleConfirmOrContinue(): Promise<void> {
  globalRemoteLogger().info(`NewCollabNotifications: Email=${isEmailOn.value}, Sms=${isSMSOn.value} for ${userProfileStore.getViewingUserProfile.sherpaId}`, true, false, {
    email: isEmailOn.value,
    sms: isSMSOn.value,
    creatorId: userProfileStore.getViewingUserProfile.sherpaId,
  });

  isSavingProfile.value = true;
  await userProfileStore.saveProfile(userProfileStore.getViewingUserProfile);
  isSavingProfile.value = false;

  if (isSMSOn.value) {
    isPhoneNumberVerificationFormVisible.value = true;
  } else {
    emit("update:isVisible", false);
  }
}

// Emit phone verification form toggle ========================================
watch(isPhoneNumberVerificationFormVisible, () => emit("phoneVerificationFormToggle", isPhoneNumberVerificationFormVisible.value));
</script>

<style scoped lang="scss">
// Switches container =========================================================
.switches-container {
  gap: 26px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  &__switch-line {
    width: auto;
    display: flex;
    align-items: center;
    cursor: pointer;
    user-select: none;
  }

  &__switch {
    margin-right: 16px;
  }

  &__text {
  }
}
</style>
