<template>
  <component
    :class="{
      'file-thumbnail': true,
      'file-thumbnail--fixed-aspect-ratio': props.isFixedAspectRatio || !isImageLoaded,
      'file-thumbnail--not-clickable': !isImageLoaded,
    }"
    :is="props.isEntireAreaClickable && props.galleryPostfix ? 'a' : 'li'"
    :data-fancybox="props.isEntireAreaClickable && props.galleryPostfix ? `gallery${props.galleryPostfix}` : null"
    :href="props.isEntireAreaClickable ? (props.fileName ? generateSrc(props.fileName, 'full') : props.filePath) : null"
    :style="{ minWidth: props.preloaderMinWidth && !isImageLoaded ? props.preloaderMinWidth : 'unset' }"
  >
    <!-- Spinner overlay -->
    <div v-if="showPlaceholder" class="spinner-overlay file-thumbnail__spinner-overlay">
      <!-- only show spinner if a filename was provided, it it wasn't provided that means just show a placeholder -->
      <IconEmbedded v-if="fileName" class="spinner-overlay__spinner" name="loader_2-5" />

      <div class="spinner-overlay__bg-wrap">
        <img v-if="_placeholderType === 'video'" class="spinner-overlay__bg" :src="iconVideoPlaceholder" alt="Video placeholder" draggable="false" />
        <img v-else class="spinner-overlay__bg" :src="iconImagePlaceholder" alt="Image placeholder" draggable="false" />
      </div>
    </div>
    <!-- / Spinner overlay -->

    <div class="file-thumbnail__video-icon-wrap">
      <IconEmbedded v-if="isVideoFileType" class="file-thumbnail__video-icon" name="video_1-5" :size="44" color="rgba(255, 255, 255, 1)" />
    </div>

    <!-- Checkbox & highlighting -->
    <label v-if="Object.keys(slots).length && !props.isRemoveButton && !props.isDeleteButton" class="checkbox-n-highlighting file-thumbnail__checkbox-n-highlighting">
      <slot />
      <span class="checkbox-n-highlighting__checkbox-imitation">
        <IconEmbedded name="check_5" :size="13" />
      </span>
      <span v-if="isImageLoaded" class="checkbox-n-highlighting__highlighting-overlay"></span>
    </label>
    <!-- / Checkbox & highlighting -->

    <!-- Fancybox button -->
    <a
      v-if="!props.isEntireAreaClickable && props.galleryPostfix && !props.isRemoveButton && !props.isDeleteButton"
      class="fancybox-button file-thumbnail__fancybox-button"
      :data-fancybox="`gallery${props.galleryPostfix}`"
      :href="props.fileName ? generateSrc(props.fileName, 'full') : props.filePath"
    >
      <IconEmbedded class="fancybox-button__icon-thick" name="maximize_2" :size="19" />
      <IconEmbedded class="fancybox-button__icon-thin" name="maximize_1-5" :size="19" />

      <img
        class="fancybox-button__image fancybox-button__image--invisible"
        :src="props.fileName ? generateSrc(props.fileName, thumbSize) : props.filePath"
        :alt="props.fileName || props.filePath"
        @click="$event => emit('clickOnImage', $event)"
      />
    </a>
    <!-- / Fancybox button -->

    <CloseDeleteButton v-if="props.isRemoveButton && isImageLoaded" class="file-thumbnail__remove-button" @click="emit('remove', props.fileName || props.filePath)" size="tiny" color="black" />

    <CloseDeleteButton
      v-if="props.isDeleteButton && isImageLoaded"
      class="file-thumbnail__delete-button"
      @click="emit('delete', props.fileName || props.filePath)"
      size="tiny"
      icon="trashcan"
      color="red"
    />
    <!-- only make attempts to render the img tag if a filename is actually provided -->
    <template v-if="props.fileName || props.filePath">
      <div v-if="props.isFixedAspectRatio || !isImageLoaded" class="file-thumbnail__thumbnail-wrap">
        <img
          class="file-thumbnail__img"
          :style="{ objectFit: props.imageFit }"
          :src="(props.fileName ? generateSrc(props.fileName, thumbSize) : props.filePath) + imageUrlQueryTimestamp"
          :alt="props.fileName || props.filePath"
          draggable="false"
          @load="
            () => {
              isImageLoaded = true;
              emit('imageLoad');
            }
          "
          @error="handleImageLoadError"
          @click="$event => emit('clickOnImage', $event)"
        />
      </div>
      <div v-else class="file-thumbnail__bg-image-wrap">
        <img
          class="file-thumbnail__bg-image"
          :src="(props.fileName ? generateSrc(props.fileName, thumbSize) : props.filePath) + imageUrlQueryTimestamp"
          :alt="props.fileName || props.filePath"
          @load="
            () => {
              isImageLoaded = true;
              emit('imageLoad');
            }
          "
          @error="handleImageLoadError"
          draggable="false"
          @click="$event => emit('clickOnImage', $event)"
        />
      </div>
    </template>
  </component>
</template>

<script setup lang="ts">
import { ref, useSlots, computed } from "vue";
import FileUtils from "@logic/FileUtils";
import generateSrc, { ThumbSize } from "@helpers/GenerateGallerySrc";

// Icons
import iconImagePlaceholder from "@assets/icons/image_placeholder.svg";
import iconVideoPlaceholder from "@assets/icons/video_placeholder.svg";
import IconEmbedded from "@components/ui/IconEmbedded.vue";
import CloseDeleteButton from "@components/ui/CloseDeleteButton.vue";

const emit = defineEmits<{
  (e: "remove", id: string): void;
  (e: "delete", id: string): void;
  (e: "imageLoad"): void;
  (e: "clickOnImage", event: Event): void;
}>();

const props = withDefaults(
  defineProps<{
    fileName?: string | null; // automatically generates thumbnails and the full size image uri for the fancybox
    filePath?: string | null;
    galleryPostfix?: string;
    isRemoveButton?: boolean;
    isDeleteButton?: boolean;
    isFixedAspectRatio?: boolean;
    thumbSize?: ThumbSize;
    imageFit?: "contain" | "cover";
    isEntireAreaClickable?: boolean;
    placeholderType?: "image" | "video";
    preloaderMinWidth?: string; // when thumbnail is used inside the flex container it shrinks to ~10px width (when image is not loaded yet) which looks ugly. So this prop prevents such shrinking
  }>(),
  {
    fileName: "",
    filePath: "",
    galleryPostfix: "",
    placeholderType: undefined,
    isRemoveButton: false,
    isDeleteButton: false,
    isFixedAspectRatio: true,
    // Note: We should likely default to a smaller size and have the few locations that need larger sizes pass it in
    thumbSize: "thumb-large",
    imageFit: "contain",
    isEntireAreaClickable: false,
    preloaderMinWidth: "",
  }
);

const slots = useSlots();

// Check isVideo ==============================================================
const isVideoFileType = computed(() => FileUtils.isVideoFileType(props.fileName || props.filePath, null));

const showPlaceholder = computed(() => !isImageLoaded.value || (!props.fileName && !props.filePath));
const _placeholderType = computed<"image" | "video">(() => props.placeholderType || (isVideoFileType.value ? "video" : "image"));

// Handle image load error ====================================================
const isImageLoaded = ref(false);
const imageUrlQueryTimestamp = ref("");
const failedCount = ref(0);
function handleImageLoadError() {
  isImageLoaded.value = false;
  failedCount.value += 1;

  const timeoutMs = 5000;
  // 60 attempts allowed (that's 5 minutes of attempts every 5 seconds)
  // this is to allow videos enough time to process and be available
  if (failedCount.value < 60) {
    setTimeout(() => (imageUrlQueryTimestamp.value = `?t=${new Date().getTime()}`), timeoutMs);
  }
}
</script>

<style scoped lang="scss">
@use "sass:math";

// Checkbox & highlighting ====================================================
.checkbox-n-highlighting {
  position: relative;
  cursor: pointer;

  :deep(input[type="checkbox"]) {
    position: absolute;
    opacity: 0;
    pointer-events: none;
  }

  &__checkbox-imitation {
    width: 31px;
    height: 31px;
    margin: 5px auto auto 5px;
    border-radius: 3px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 0;
    color: transparent;
    background: rgba(255, 255, 255, 0.45);
    //Caused issues when lots of photos: backdrop-filter: blur(10px);

    &::after {
      content: "";
      width: 15px;
      height: 15px;
      box-sizing: border-box;
      border: 1px #c1c1c1 solid;
      border-radius: 3px;
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      inset: 8px auto auto 8px;
      z-index: -1;
      background: #fff;
    }
  }
  :deep(input[type="checkbox"]) + &__checkbox-imitation {
    svg {
      fill: rgba(0, 0, 0, 0);
    }
  }
  :deep(input[type="checkbox"]:checked) + &__checkbox-imitation {
    svg {
      fill: rgba(0, 0, 0, 1);
    }
  }

  &__highlighting-overlay {
    width: 100%;
    height: 100%;
    border-radius: 6px;
    outline: 4px #118689 solid;
    outline-offset: -2px;
    position: absolute;
    inset: 0 auto auto 0;
    z-index: -1;
    opacity: 0;
    background: rgba(#118689, 0.35);
  }
  :deep(input[type="checkbox"]:checked) + &__checkbox-imitation + &__highlighting-overlay {
    opacity: 1;
  }
}

// Fancybox button ============================================================
.fancybox-button {
  width: 21px;
  height: 21px;
  border-radius: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: scale(1);
  transform-origin: top right;
  color: #fff;
  font-size: 17px;
  line-height: 17px;
  background: rgba(0, 0, 0, 0.15);
  //Caused issues when lots of photos: backdrop-filter: blur(10px);
  cursor: pointer;
  transition: transform 0.07s ease-in-out;

  &::after {
    content: "";
    width: calc(100% + 14px);
    height: calc(100% + 14px);
    border-radius: 6px;
    position: absolute;
    inset: 50% auto auto 50%;
    transform: translate(-50%, -50%);
  }

  &:hover {
    transform: scale(1.4);
  }

  &__icon-thick {
    display: block;

    :deep(svg) {
      fill: rgba(255, 255, 255, 1);
    }
  }
  &:hover &__icon-thick {
    display: none;
  }

  &__icon-thin {
    display: none;

    :deep(svg) {
      fill: rgba(255, 255, 255, 1);
    }
  }
  &:hover &__icon-thin {
    display: block;
  }

  &__image {
    &--invisible {
      display: none;
    }
  }
}

// Spinner overlay ============================================================
.spinner-overlay {
  width: 100%;
  height: 100%;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 0;
  background: #d7d7d7;

  &__spinner {
    width: 34px;
    height: 34px;
    font-size: 34px;
    line-height: 34px;
    /* Fallback: Set a background color. */
    background-color: transparent;
    /* Create the gradient. */
    background-image: conic-gradient(from -22deg, rgba(0, 0, 0, 0), rgba(0, 0, 0, 1));
    /* Set the background size and repeat properties. */
    background-size: 100%;
    background-repeat: repeat;
    /* Use the text as a mask for the background. */
    /* This will show the gradient as a text color rather than element bg. */
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    -moz-background-clip: text;
    -moz-text-fill-color: transparent;

    animation-name: gradientSpin;
    animation-duration: 0.6s;
    animation-iteration-count: infinite;
    animation-timing-function: step-start;
    background: conic-gradient(from 110deg, rgba(0, 0, 0, 0), rgba(0, 0, 0, 1));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

    opacity: 0.55;

    /* @keyframes duration | easing-function | delay | iteration-count | direction | fill-mode | play-state | name */
    @keyframes gradientSpin {
      0% {
        background: conic-gradient(from 110deg, rgba(0, 0, 0, 0), rgba(0, 0, 0, 1));
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
      12.5% {
        background: conic-gradient(from 155deg, rgba(0, 0, 0, 0), rgba(0, 0, 0, 1));
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
      25% {
        background: conic-gradient(from 200deg, rgba(0, 0, 0, 0), rgba(0, 0, 0, 1));
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
      37.5% {
        background: conic-gradient(from 245deg, rgba(0, 0, 0, 0), rgba(0, 0, 0, 1));
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
      50% {
        background: conic-gradient(from 290deg, rgba(0, 0, 0, 0), rgba(0, 0, 0, 1));
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
      62.5% {
        background: conic-gradient(from 335deg, rgba(0, 0, 0, 0), rgba(0, 0, 0, 1));
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
      75% {
        background: conic-gradient(from 20deg, rgba(0, 0, 0, 0), rgba(0, 0, 0, 1));
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
      87.5% {
        background: conic-gradient(from 65deg, rgba(0, 0, 0, 0), rgba(0, 0, 0, 1));
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
      100% {
        background: conic-gradient(from 110deg, rgba(0, 0, 0, 0), rgba(0, 0, 0, 1));
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }
  }

  &__bg-wrap {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    inset: 0 auto auto 0;
    z-index: -1;
  }

  &__bg {
    width: 60%;
    height: 60%;
    opacity: 0.07;
  }
}

// File thumbnail =============================================================
.file-thumbnail {
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 0;
  user-select: none;

  &--not-clickable {
    pointer-events: none;
  }

  &--fixed-aspect-ratio {
    aspect-ratio: 4/3;
  }

  &__spinner-overlay {
    position: absolute;
    inset: 0 auto auto 0;
    z-index: 7;
  }

  &__video-icon-wrap {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    inset: 0 auto auto 0;
    z-index: 4;
    pointer-events: none;
  }

  &__video-icon {
    width: 44px;
    min-width: 22px;
    max-width: calc(100% - 12px);
    max-height: calc(100% - 12px);
    aspect-ratio: 1/1;
    opacity: 1;
  }

  &__checkbox-n-highlighting {
    width: 100%;
    height: 100%;
    position: absolute;
    inset: 0 auto auto 0;
    z-index: 5;
  }

  &__fancybox-button {
    position: absolute;
    inset: 5px 5px auto auto;
    z-index: 5;
  }

  &__remove-button,
  &__delete-button {
    position: absolute;
    inset: -3px -3px auto auto;
    z-index: 5 !important;
  }

  &__thumbnail-wrap {
    width: 100%;
    height: 100%;
    border-radius: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    inset: 0 auto auto 0;
    z-index: 1;
    overflow: hidden;
    //Caused issues when lots of photos: backdrop-filter: blur(15px);
    will-change: filter;
    background: #dadada;
  }

  &__img {
    width: 100%;
    height: 100%;
  }

  &__bg-image-wrap {
    width: 100%;
    height: 100%;
    border-radius: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    overflow: hidden;
  }

  &__bg-image {
    width: 100%;
    height: 100%;
    border-radius: 6px;
    object-fit: cover;
    will-change: filter;
  }
}
</style>
