<template>
  <MenuLinksList :linksList="communityIdInContext ? navLinksAdmin : []" />
</template>

<script lang="ts">
import { defineComponent } from "vue";
import OrgContext from "@logic/OrgContext";
import { useUserProfileStore } from "@stores/userProfileStore";
import { mapState } from "pinia";

// Types
import { NavigationMenuItem } from "@contracts/navigationMenuItem";

// Components
import MenuLinksList from "@components/Navigation/MenuLinksList.vue";

// NOTE: Currently using the dashboard redirect parameter.
// An alternative would be to get the community in context here (but we'd have to react to dropdown changes on the dashboard anyway...)

export default defineComponent({
  name: "CustomerAdminLeftNav",

  components: {
    MenuLinksList,
  },

  data() {
    return {
      currentRouteName: "",
      communityIdInContext: "",
      hotelInContext: false,
    };
  },

  computed: {
    ...mapState(useUserProfileStore, ["getViewingUserProfile"]),
    orgInContext() {
      return this.globalRoot.orgInContext;
    },
    navLinksAdmin(): Array<NavigationMenuItem> {
      // prettier-ignore
      return [
        { text: "Dashboard", isSubItem: false, titleIcon: "dashboard_2", to: { name: "CommunityAdminDashboard", params: { communityId: this.communityIdInContext } } },
        { text: "Content Creation", isSubItem: false, titleIcon: "aperture_2", to: { name: "PaidCollabCommunityAdmin", params: { communityId: this.communityIdInContext } }, isHidden: this.orgInContext?.isDisabled, },
        { text: "Creator Visits", isSubItem: true, to: { name: "PaidCollabCommunityAdmin", params: { communityId: this.communityIdInContext } }, isHidden: this.orgInContext?.isDisabled },
        { text: "Community Uploads", isSubItem: true, to: { name: "UserUploadCampaigns", params: { pageId: this.communityIdInContext } }, isHidden: this.orgInContext?.isDisabled },
        // Only show the Create an Adventure link to certain admins
        ...(this.orgInContext?.enableCreateAdventures ?
        [{ text: "Create an Adventure", isSubItem: true, to: { name: "MyItineraries", params: { creatorId: this.getViewingUserProfile?.sherpaId } }, isHidden: this.orgInContext?.isDisabled }]
        : []),
        { text: "My Content", isSubItem: false, titleIcon: "briefcase_2", to: { name: "CommunityPhotosV2", params: { pageId: this.communityIdInContext } } },
        { text: "Photos & Videos", isSubItem: true, to: { name: "CommunityPhotosV2", params: { pageId: this.communityIdInContext } } },
        { text: "Adventures", isSubItem: true, to: { name: "CommunityAdventureApproval", params: { pageId: this.communityIdInContext } }, isHidden: this.orgInContext?.isDisabled },
        { text: "Content Uses", isSubItem: false, titleIcon: "check-circle_2-5", to: { name: "WidgetDirections", params: { pageId: this.communityIdInContext } }, isHidden: this.orgInContext?.isDisabled, },
        { text: "Adventure Wall", isSubItem: true, name2: "CustomIntegration", to: { name: "AdventureWidgets", params: { pageId: this.communityIdInContext } }, isHidden: this.orgInContext?.isDisabled, },
        { text: "Adventure Previews", isSubItem: true, to: { name: "AdventureSummary", params: { pageId: this.communityIdInContext } }, isWithBetaBadge: true, isHidden: this.orgInContext?.isDisabled },
        { text: "Photo Wall", isSubItem: true, name2: "PhotoWallAdmin", to: { name: "WidgetDirections", params: { pageId: this.communityIdInContext } }, isHidden: this.orgInContext?.isDisabled },
        { text: "Share on Social", isSubItem: true, to: { name: "ShareStopSummaryV2", params: { pageId: this.communityIdInContext } }, isHidden: this.orgInContext?.isDisabled },
        { text: "Print Media", isSubItem: true, to: { name: "PrintMedia", params: { pageId: this.communityIdInContext } }, isHidden: this.orgInContext?.isDisabled },
        { text: "Settings", isSubItem: false, titleIcon: "gear_2", to: { name: "CommunityPageAdmin", params: { pageId: this.communityIdInContext } }, isDisabled: this.orgInContext?.isDisabled },
        { text: "Destination Page", isSubItem: true, to: { name: "CommunityPageAdmin", params: { pageId: this.communityIdInContext } }, isHidden: this.orgInContext?.isDisabled },
        { text: "Subscription", isSubItem: true, to: { name: "SubscriptionSettings", params: { pageId: this.communityIdInContext } } },
        { text: "Users", isSubItem: true, to: { name: "UserAdmin", params: { pageId: this.communityIdInContext } }, isHidden: this.orgInContext?.isDisabled },
      ];
    },
  },

  watch: {
    $route(to, from) {
      this.setRouteName();
      console.info(this.currentRouteName);
    },
    // This would allow us to avoid the redirect through dashboard routes
    "globalRoot.orgInContext": {
      handler: async function (orgInContext) {
        if (orgInContext && orgInContext?.customerId != null) {
          this.setupOrgInContext();
        }
      },
    },
  },

  async mounted() {
    this.setRouteName();
    this.setupOrgInContext();
  },

  methods: {
    setRouteName() {
      this.currentRouteName = this.$router.currentRoute.value.name?.toString();
    },
    setupOrgInContext() {
      const orgInContext = OrgContext.getOrgInContext(this);

      if (orgInContext) {
        this.communityIdInContext = orgInContext?.customerId;
        this.hotelInContext = OrgContext.isOrgInContextHotel(this);
      }
    },
    isActive(name, name2?, name3?, name4?, name5?): boolean {
      return [name, name2, name3, name4, name5].includes(this.currentRouteName);
    },
  },
});
</script>
